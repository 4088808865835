import Link from 'next/link';

const HeroLanding = () => {
    return (
        <>
            <div className="hero-landing">
                <video id="background-video" loop autoPlay muted>
                    <source src="intro.mp4" type="video/mp4" />
                    <source src="intro.webm" type="video/webm" />
                    Your browser does not support the video tag.
                </video>
                <div className="hero-claim">
                    <h2 className="logo">
                        <img
                            src="/assets/logo_LPF_horizontal.png"
                            alt="logo LPF"
                        />
                    </h2>
                    <h3>
                        Accedé a todos los contenidos de <br />
                        la Liga de los Campeones del Mundo
                    </h3>
                    <div className="hero-login">
                        <p className="secondary-button">
                            <Link href="/signin">Ingresá con tu cuenta</Link>
                        </p>
                        <p> ó </p>
                        <p className="primary-button">
                            <Link href="/signup-complete">Registrate</Link>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export { HeroLanding };
