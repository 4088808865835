import { useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NavbarLoggedOut } from './NavbarLoggedOut';
import { NavbarLogged } from './NavbarLogged';
import Link from 'next/link';
import { AppContext } from '@/context/appContext';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';

const TIME_DELAY_SEARCH = 1000;

const Header = () => {
    const [state, dispatch] = useContext(AppContext);
    // Store timer to delay search
    const [timer, setTimer] = useState('');
    const { data: session } = useSession();

    const router = useRouter();

    const getSearchIcon = () => {
        return session ? (
            <FontAwesomeIcon
                className="searchIcon"
                icon={faSearch}
                onClick={() => setSearchBarActive(true)}
            />
        ) : null;
    };

    // Header for logged / logged out users
    const getNavigateComponent = () => {
        return session ? <NavbarLogged /> : <NavbarLoggedOut />;
    };

    // Header with active / inactive search bar
    const [searchBarActive, setSearchBarActive] = useState(false);

    // Estado de la búsqueda
    const [search, setSearch] = useState('');

    // Buscador
    const searchVideos = (e) => {
        const value = e.target ? e.target.value : '';
        setSearch(value);
        if (timer) {
            clearTimeout(timer);
        }
        const newTimer = setTimeout(() => {
            dispatch({ type: 'SEARCH_CATERGORY_TERM', payload: value });
        }, TIME_DELAY_SEARCH);
        setTimer(newTimer);
    };

    // Change header background colour
    const [headerActive, setHeaderActive] = useState(false);

    useEffect(() => {
        const changeHeaderBackgroundColour = () => {
            if (window.scrollY > 15) {
                setHeaderActive(true);
            } else {
                setHeaderActive(false);
            }
        };
        if (typeof window !== undefined) {
            window.addEventListener('scroll', changeHeaderBackgroundColour);
        }
        const handleRouteChange = (url, { shallow }) => {
            setSearch('');
            setSearchBarActive(false);
            dispatch({ type: 'SEARCH_CATERGORY_TERM', payload: '' });
        };

        router.events.on('routeChangeStart', handleRouteChange);
    }, []);

    return (
        <div className={headerActive ? 'header header-active' : 'header'}>
            <Link href="/">
                <a href="#">
                    <img src="/assets/logo_LPF_horizontal.png" alt="logo LPF" />
                </a>
            </Link>
            <div className="rightNav">
                {getSearchIcon()}
                <div
                    className={
                        searchBarActive
                            ? 'searchBar searchBar-active'
                            : 'searchBar'
                    }
                >
                    <input
                        id="searchInput"
                        className="searchInput"
                        type="text"
                        value={search}
                        placeholder="Buscar..."
                        onChange={searchVideos}
                    />
                    <FontAwesomeIcon
                        className="closeInput"
                        icon={faTimes}
                        onClick={() => {
                            setSearchBarActive(false);
                            searchVideos('');
                        }}
                    />
                </div>
                {getNavigateComponent()}
            </div>
        </div>
    );
};

export { Header };
