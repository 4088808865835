import Link from 'next/link';

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-top">
                <div className="footer-left">
                    <div className="mobile-apps">
                        <p>Descargate las aplicaciones móviles:</p>
                        <div className="mobile-apps-links">
                            <Link href="https://apps.apple.com/us/app/lpf-play/id1664764849">
                                <img
                                    src="/assets/apple_appstore_b.webp"
                                    alt="Descargar aplicación móvil de LPF Play en Apple App Store"
                                />
                            </Link>
                            <Link href="/">
                                <img
                                    src="/assets/google_playstore_b.png"
                                    alt="Descargar aplicación móvil de LPF Play en Google Play"
                                />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="footer-right">
                    <span>SEGUINOS EN:</span>
                    <div className="social">
                        <Link href="https://twitter.com/LPFPlay" target="_blank">
                            <img
                                src="/assets/tw.svg"
                                alt="Ir a cuenta de Twitter"
                            />
                        </Link>
                        <Link href="https://www.facebook.com/LPFPlay" target="_blank">
                            <img
                                src="/assets/fb.svg"
                                alt="Ir a cuenta de Facebook"
                            />
                        </Link>
                        <Link href="https://www.instagram.com/lpfplay/" target="_blank">
                            <img
                                src="/assets/ig.svg"
                                alt="Ir a cuenta de Instagram"
                            />
                        </Link>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                
                    <Link href="/">
                        <img src="/assets/logo_LPF_horizontal.png" alt="logo LPF Play" />
                    </Link>

                    
                
                

                <div className="footer-text">
                    
                    <p>
                        <Link className='terms' href="/contact">
                            Contacto / Ayuda
                        </Link>

                    </p>
                    
                    <p>
                        <Link className='terms' href="/terms">
                            Términos y condiciones
                        </Link>

                    </p>
                    
                    <p>
                        © 2023{' '}
                        <a href="https://www.ligaprofesional.ar/" target="_blank" rel="noreferrer">
                            Liga Profesional de Fútbol de AFA
                        </a>
                    </p>
                    <p className="powered">
                        Powered by 
                        <a href="http://poipes.io" target="_blank" rel="noreferrer">
                            Poipes
                        </a>
                    </p>
                    {/* <p>Google Play and the Google Play logo are trademarks of Google LLC.</p> */}
                </div>
            </div>
        </div>
    );
};

export { Footer };
