import Link from 'next/link';

const NavbarLoggedOut = () => {
    return (
        <div className="navbar">
            <Link href="/signin">
                <a className="secondary-button" href="#">
                    Ingresá
                </a>
            </Link>
            <Link href="/signup-complete">
                <a className="primary-button" href="#">
                    Registrate
                </a>
            </Link>
        </div>
    );
};

export { NavbarLoggedOut };
