import Layout from '@/components/Layout';
import Link from 'next/link';
import { getSession } from 'next-auth/react'; 
import { HeroLanding } from '@/components/HeroLanding'; 
import { HeroLandingMobile } from '@/components/HeroLandingMobile'; 
import { useEffect } from 'react';

export default function LandingPage() {  
  
  // first, we check the device width 
  let deviceWidth; 

  useEffect(() => {
      deviceWidth = window.innerWidth;  
  }, []) 
    
  // then, we render different components for mobile / larger screens 
  if (deviceWidth > 480) {
    return (
      <>
        <div suppressHydrationWarning={true} className="landing-page">
          <HeroLandingMobile />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div suppressHydrationWarning={true} className="landing-page">
          <HeroLanding />
        </div>
      </>
    );
  }
}

LandingPage.getLayout = function getLayout(page) {
  return (
    <Layout title="LPF Play | Todos los contenidos de la Liga de los Campeones del Mundo">
      {page}
    </Layout>
  );
};

export async function getServerSideProps(ctx) {
    const session = await getSession(ctx);

    if (session) {
        return {
            redirect: {
                permanent: false,
                destination: '/browse',
            },
            props: { session },
        };
    }

    // Pass data to the page via props
    return { props: { session } };
}
